import { type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { GET_BOOKABILITY_STATUS_URL } from "@src/appV2/OpenShifts/ShiftAction/api/useBookabilityStatus";
import { QuizFailedDialog } from "@src/appV2/WorkplaceQuiz/Dialogs/QuizFailedDialog";
import { QuizFormDialog } from "@src/appV2/WorkplaceQuiz/Dialogs/QuizFormDialog";
import { RulesDialog } from "@src/appV2/WorkplaceQuiz/Dialogs/QuizRulesDialog";
import {
  type GetWorkplaceQuizResultLogsResponse,
  type WorkplaceQuizData,
  type WorkplaceQuizResultLogSchema,
} from "@src/appV2/WorkplaceQuiz/types";
import { type WorkplaceRulesProps } from "@src/appV2/WorkplaceQuiz/WorkplaceRules/WorkplaceRules";
import { type QueryObserverResult, useQueryClient } from "@tanstack/react-query";

export interface WorkplaceRulesWithQuizProps {
  facilityName: string;
  facilityNotes: WorkplaceRulesProps["facilityNotes"];
  quiz: WorkplaceQuizData;
  quizRulesDialog: UseModalState;
  onQuizPassed: () => void;
  logMetadata: {
    source: "facility_page" | "booking" | "negotiation";
    shiftId?: string;
  };
  latestQuizResultLog?: WorkplaceQuizResultLogSchema;
  refetchQuizResultLogs?: () => Promise<QueryObserverResult<GetWorkplaceQuizResultLogsResponse>>;
  onCancel?: () => void;
}

export function WorkplaceRulesWithQuiz(props: WorkplaceRulesWithQuizProps) {
  const {
    facilityNotes,
    quizRulesDialog,
    facilityName,
    quiz,
    refetchQuizResultLogs,
    onQuizPassed,
    logMetadata,
    latestQuizResultLog,
    onCancel,
  } = props;

  const queryClient = useQueryClient();
  const quizFormDialog = useModalState();
  const quizFailedDialog = useModalState();
  const logProperties = { ...logMetadata, workplaceId: quiz.attributes.workplaceId };

  return (
    <>
      <RulesDialog
        facilityNotes={facilityNotes}
        workplaceName={facilityName}
        modalState={quizRulesDialog}
        latestQuizResultLog={latestQuizResultLog}
        onStartQuiz={() => {
          quizRulesDialog.closeModal();
          quizFormDialog.openModal();
          logEvent(APP_V2_USER_EVENTS.VIEWED_WORKPLACE_QUIZ_PAGE, logProperties);
        }}
        onRulesClosed={() => {
          quizRulesDialog.closeModal();
          logEvent(APP_V2_USER_EVENTS.CLOSED_WORKPLACE_RULES_PAGE, logProperties);

          if (isDefined(onCancel)) {
            onCancel();
          }
        }}
      />
      <QuizFormDialog
        modalState={quizFormDialog}
        workplaceName={facilityName}
        quiz={quiz}
        onQuizComplete={async () => {
          await queryClient.invalidateQueries([GET_BOOKABILITY_STATUS_URL]);
          if (isDefined(refetchQuizResultLogs)) {
            await refetchQuizResultLogs();
          }
        }}
        onQuizClosed={() => {
          quizFormDialog.closeModal();
          quizRulesDialog.openModal();
          logEvent(APP_V2_USER_EVENTS.CLOSED_WORKPLACE_QUIZ_PAGE, logProperties);
        }}
        onQuizFailed={() => {
          logEvent(APP_V2_USER_EVENTS.VIEWED_QUIZ_FAILED_DIALOG, logProperties);
          quizFailedDialog.openModal();
        }}
        onQuizPassed={() => {
          logEvent(APP_V2_USER_EVENTS.VIEWED_QUIZ_PASSED_DIALOG, logProperties);
          quizFormDialog.closeModal();
          onQuizPassed();
        }}
      />
      <QuizFailedDialog
        modalState={quizFailedDialog}
        facilityName={facilityName}
        onRetakeQuiz={() => {
          logEvent(
            APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_RETAKE_QUIZ_ON_QUIZ_FAILED_DIALOG,
            logProperties
          );
          quizFailedDialog.closeModal();
          quizFormDialog.closeModal();
          quizRulesDialog.openModal();
        }}
        onCancel={() => {
          logEvent(
            APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_CANCEL_ON_QUIZ_FAILED_DIALOG,
            logProperties
          );
          quizFailedDialog.closeModal();
          quizFormDialog.closeModal();

          if (isDefined(onCancel)) {
            onCancel();
          }
        }}
      />
    </>
  );
}
